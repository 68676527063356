import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ConfigService } from '@scpc/modules/common/services/config.service';
import { CmsService } from '@scpc/modules/common/services/cms.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Settings } from '@scpc/dto/settings';

@Injectable({ providedIn: 'root' })
export class ProductGuard implements CanActivate {

  constructor(
    private readonly configService: ConfigService,
    private readonly cmsService: CmsService,
    private readonly router: Router
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if (!this.configService.initialized()) {
      return this.cmsService.getSettings().pipe(map((settings: Settings) => {
        this.configService.init(settings);
      }), switchMap(() => this.isAllowed(route)));
    }
    return this.isAllowed(route);
  }

  private isAllowed(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if (!this.configService.products.find((p) => p.type === route.data.product)) {
      return of(this.router.parseUrl('/'));
    }
    return of(true);
  }

}
